* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Inter", sans-serif;
}

body {
  background: #eee;
}

header {
  margin: auto 0px;
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar__brand {
  color: rgb(110, 110, 110);
  font-weight: 200;
  font-size: 28px;
  text-decoration: none;
}

.navbar__brand img {
  height: 35px;
  margin-right: 10px;
}

.navbar__link {
  color: #00b2e3;
  font-weight: 400;
  font-size: 28px;
  text-decoration: none;
  margin: 0 .25rem 0 30px;
  box-shadow: inset 0 0 0 0 #00b2e3;
  padding: 0 .45rem 0 .29rem;
  border-radius: 5px;
  transition: color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.navbar__link:hover {
  text-decoration: underline;
}

.navbar__link.active {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #00b2e3;
  text-decoration: none;
}

footer {
  position: absolute;
  text-align: center;
  bottom: 0px;
  width: 100%;
  height: 2.5rem;
}

footer p {
  color: #fff;
}

.form {
  width: 90%;
  margin-top: 20px;
  margin-inline: auto;
}

.form ul {
  padding: 0;
  margin: 0;
}

.form ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  display: block;
  clear: both;
  position: relative;
  animation: fadeInUp 2s 2s;
  animation-fill-mode: forwards;
}

.form ul .form__left {
  width: 49%;
  float: left;
  clear: none;
}

.form ul .form__right {
  width: 49%;
  margin-left: 2%;
  float: left;
  clear: none;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'] {
  width: 100%;
  border: 0;
  background: #CEEEFF;
  height: 50px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  padding: 0 20px;
}

.form input[type='radio'] {
  margin-left: 50px;
  margin-right: 5px;
}

.form .checkBox {
  display: flex;
  justify-content: right;
}

.form .checkBox label {
  margin-top: 9px;
  margin-right: 30px;
}

.form .checkBox input[type='checkbox'] {
  position: relative;
  top: 1.6px;
}

.form textarea {
  width: 100%;
  border: 0;
  background: #CEEEFF;
  height: 50px;
  font-size: 16px;
  font-family: sans-serif;
  color: rgb(0, 0, 0);
  padding: 20px;
  margin-bottom: -10px;
  min-height: 150px;
}

.form__button {
  color: #000000;
  background: 0 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 3px solid #00E3A3;
  border-radius: 5px;
  float: right;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
}

.form__button:hover {
  background: #00E3A3;
  color: rgb(255, 255, 255);
}

.form li span {
  background: #00E3A3;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 3px solid #00E3A3;
  border-radius: 5px;
  float: right;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
}

.form__head {
  display: flex;

  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}

.form__time {
  width: 70%;
  display: flex;
  justify-content: left;
}

.form__timePicker {
  margin-right: 18px;
}

.form__format {
  background: #CEEEFF;
  width: 28%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

.template__menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.template__delete {
  display: none;
  margin: 0;
  position: relative;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  outline: none;
  align-items: center;
  color: #1E6AE1;
}

.template__delete:hover {
  color: #00E3A3;
}

.template__delete:active {
  color: #FF0000;
}

.template:hover .template__delete {
  display: block;
}

#physical:checked:checked~.physical,
#online:checked:checked~.online,
#hybrid:checked:checked~.hybrid {
  border-color: #1E6AE1;
  background: #1E6AE1;
}

.form__format span {
  font-size: 20px;
  color: #808080;
}

#physical:checked:checked~.physical span,
#online:checked:checked~.online span,
#hybrid:checked:checked~.hybrid span {
  color: #fff;
}

.events {
  width: 70%;
  margin-top: 20px;
  margin-inline: auto;
}

.events a:link {
  text-decoration: none;
}

.events__card {
  transition: all .25s linear;
}

.events__card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

@media (max-width: 500px) {
  header {
    padding: 5px 8px;
  }

  nav {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
  }

  .navbar__brand {
    font-size: 18px;
  }

  .navbar__link {
    font-size: 18px;
    padding: 5px 15px;
    margin: 0;
  }

  .form__time {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .form__timePicker {
    margin-right: 8px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .events {
    width: 90%;
    margin-top: 20px;
    margin-inline: auto;
  }
}